import Vue from 'vue'

import PageHero from './layouts/PageHero'
import Page from './layouts/Page'

import Tag from './globals/Tag'
import Busy from './globals/Busy'
import Card from './globals/Card'
import Chip from './globals/Chip'
import Grid from './globals/Grid'
import Icon from './globals/Icon'
import Modal from './globals/Modal'
import TabNav from './globals/TabNav'
import Backdrop from './globals/Backdrop'
import Pagination from './globals/Pagination'
import DropdownMenu from './globals/DropdownMenu'

import DynamicField from './globals/DynamicField'

import Field from './form/Field'
import Selector from './form/Selector'
import Action from './form/Action'
import Confirm from './form/Confirm'
import Checkbox from './form/Checkbox'
import ActionIcon from './form/ActionIcon'

Vue.component('page-hero', PageHero)
Vue.component('page', Page)

Vue.component('card', Card)
Vue.component('tag', Tag)
Vue.component('icon', Icon)
Vue.component('grid', Grid)
Vue.component('chip', Chip)
Vue.component('busy', Busy)
Vue.component('modal', Modal)
Vue.component('backdrop', Backdrop)
Vue.component('pagination', Pagination)
Vue.component('tab-nav', TabNav)
Vue.component('dropdown-menu', DropdownMenu)

Vue.component('field', Field)
Vue.component('selector', Selector)
Vue.component('checkbox', Checkbox)
Vue.component('action', Action)
Vue.component('confirm', Confirm)
Vue.component('action-icon', ActionIcon)

Vue.component('dynamic-field', DynamicField)
