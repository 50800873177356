<template>
  <div class="rounded">
    <div class="
        my-1
        mx-1
        border border-gray-200
        shadow-sm
        pb-4
        bg-white
        hover:border-active
      " :class="{
        'ring ring-blue-300 bg-blue-100': isSelected(img)
      }">
      <button type="button" class="w-full square relative" @click="onClick(img)">
        <figure v-if="img._isImage" class="
            absolute
            inset-4
            no-pattern-bg
            flex
            items-center
            justify-center
          ">
          <img :src="img._thumb" class="max-w-full max-h-full" :id="img._key" @load="onImageLoad(img)" />
        </figure>
        <div v-else class="absolute inset-4 bg-white flex items-center justify-center">
          <img :src="fileIcon" class="w-8 h-auto" :id="img._key" />
        </div>
      </button>
      <div class="px-4 flex flex-row items-center -mt-4">
        <p class="
            flex-1
            text-xs text-gray-700
            max-w-full
            font-semibold
            whitespace-nowrap
            overflow-hidden overflow-ellipsis
          ">
          {{ img.original }}
        </p>
        <chip size="xs" variant="blue" class="uppercase">{{ img._mime }}</chip>
      </div>
      <div class="px-4 text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
        <span class="text-blue-700" v-if="dimensions">
          {{ dimensions.width }} &times; {{ dimensions.height }}px
        </span>
        <span class="text-gray-400 ml-1">{{ img._niceSize.value }}{{ img._niceSize.unit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import fileIcon from '@/assets/file-icon.png'

export default {
  name: 'MediaCard',
  props: {
    img: { type: Object },
    selected: { type: Array, default: () => ({}) },
    dimensions: { type: Object, default: () => ({}) }
  },
  computed: {
    fileIcon() {
      return fileIcon
    }
  },
  methods: {
    isSelected({ _id }) {
      return this.selected.includes(_id)
    },
    onClick(img) {
      this.$emit('select', img)
    },
    onImageLoad(img) {
      this.$emit('load', img)
    }
  }
}
</script>
