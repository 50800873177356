var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.isUploading)?_c('div',{staticClass:"\n      absolute\n      inset-0\n      bg-white bg-opacity-75\n      flex\n      items-center\n      justify-center\n      z-50\n      text-blue-600\n    "},[_c('icon',{attrs:{"face":"mdiRefresh","size":"4rem","spin":""}})],1):_vm._e(),_c('div',{class:{
    'overflow-y-auto': !_vm.currentImage && !_vm.hasOverride,
    'overflow-hidden': _vm.currentImage && !_vm.hasOverride
  },style:(_vm.computedStyle)},[_c('div',{staticClass:"flex flex-row flex-wrap"},_vm._l((_vm.paginatedThumbs),function(img){return _c('media-card',{key:img._key,staticClass:"w-1/2 sm:w-1/3 lg:w-1/6",attrs:{"img":img,"selected":_vm.selected,"dimensions":_vm.imageDimensions[img._key]},on:{"select":function($event){return _vm.onSelect(img)},"load":function($event){return _vm.onImageLoad(img)}}})}),1),_c('div',{staticClass:"py-8 flex items-center justify-center"},[_c('pagination',{attrs:{"page":_vm.page,"last":_vm.lastPage,"count":_vm.thumbs.length},on:{"change":_vm.onChangePage}})],1)]),(_vm.currentImage)?_c('div',{staticClass:"absolute inset-0 flex flex-col md:flex-row bg-white"},[_c('button',{staticClass:"\n        absolute\n        right-2\n        top-2\n        w-10\n        h-10\n        text-2xl\n        flex\n        items-center\n        justify-center\n        cursor-pointer\n        bg-transparent\n        text-gray-600\n        hover:text-black\n        bg-white\n        rounded-sm\n        shadow\n        z-50\n      ",attrs:{"disabled":_vm.isBusy,"type":"button"},on:{"click":function($event){_vm.currentImage = null}}},[_c('icon',{attrs:{"face":"mdiWindowClose"}})],1),(_vm.currentImage._isImage)?_c('div',{staticClass:"w-full flex-1 p-1 bg-gray-100"},[_c('div',{staticClass:"no-pattern-bg h-full w-full relative"},[_c('div',{staticClass:"\n            absolute\n            bg-no-repeat bg-center bg-contain\n            inset-0\n            md:inset-2\n          ",style:({
            backgroundImage: ("url(" + (_vm.currentImage._thumb) + ")")
          })})])]):_vm._e(),_c('div',{staticClass:"w-full p-4 bg-gray-100 flex-shrink-0",class:{
      'md:w-1/3': _vm.currentImage._isImage
    }},[_c('div',{staticClass:"h-full relative"},[_c('div',{staticClass:"overflow-y-auto"},[_vm._l((_vm.currentImageDetails),function(item){return _c('div',{key:item.id},[_c('label',{staticClass:"font-bold text-sm"},[_vm._v(_vm._s(_vm.$t(item.label)))]),_c('p',{staticClass:"\n                text-xs\n                whitespace-nowrap\n                max-w-full\n                overflow-hidden overflow-ellipsis\n              ",attrs:{"title":item.value}},[_vm._v(" "+_vm._s(item.value)+" ")])])}),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"font-bold text-sm"},[_vm._v(_vm._s(_vm.$t('media.url')))]),_c('a',{staticClass:"\n                text-blue-500\n                whitespace-nowrap\n                max-w-full\n                overflow-hidden overflow-ellipsis\n                block\n                hover:underline\n                text-xs\n              ",attrs:{"target":"_blank","download":_vm.currentImage.original,"href":_vm.currentImage._thumb,"title":_vm.currentImage._thumb}},[_vm._v(_vm._s(_vm.currentImage._thumb))])])],2),_c('hr'),_c('div',{staticClass:"flex-shrink-0 flex mt-1"},[_c('confirm',{staticClass:"mt-4 md:mt-0",attrs:{"icons-only":"","disabled":_vm.isBusy},on:{"click":_vm.onImageDelete}})],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }