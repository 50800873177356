import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Unauthorized from './views/Unauthorized.vue'

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ './views/About.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: { public: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { bc: 'profile.nav' },
    component: () => import(/* webpackChunkName: "profile" */ './views/Profile')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/settings/contacts',
    component: () =>
      import(
        /* webpackChunkName: "settings-contacts" */ './views/ContactsSettings'
      )
  },
  {
    path: '/settings/estates',
    component: () =>
      import(
        /* webpackChunkName: "settings-estates" */ './views/EstatesSettings'
      )
  },
  {
    path: '/estates',
    component: () => import(/* webpackChunkName: "estates" */ './views/Estates')
  },
  {
    path: '/archives',
    component: () =>
      import(/* webpackChunkName: "estates" */ './views/Archives')
  },
  {
    path: '/estates/create',
    component: () =>
      import(/* webpackChunkName: "estate-editor" */ './views/EstateEditor')
  },
  {
    path: '/estates/:id/edit',
    component: () =>
      import(/* webpackChunkName: "estate-editor" */ './views/EstateEditor')
  },
  {
    path: '/estates/:id/view',
    component: () =>
      import(/* webpackChunkName: "estate-viewer" */ './views/EstateViewer')
  },
  {
    path: '/archives/:id/view',
    component: () =>
      import(/* webpackChunkName: "estate-viewer" */ './views/EstateViewer')
  },
  {
    path: '/contacts',
    component: () =>
      import(/* webpackChunkName: "contacts" */ './views/Contacts')
  },
  {
    path: '/media',
    component: () => import(/* webpackChunkName: "media" */ './views/Media')
  },
  {
    path: '/lists/zones',
    component: () =>
      import(/* webpackChunkName: "list-zones" */ './views/Zones')
  },
  {
    path: '/lists/types',
    component: () =>
      import(/* webpackChunkName: "list-types" */ './views/Types')
  },
  {
    path: '/lists/templates',
    component: () =>
      import(/* webpackChunkName: "list-templates" */ './views/Templates')
  },
  {
    path: '/lists/users',
    component: () =>
      import(/* webpackChunkName: "list-users" */ './views/Users')
  },
  {
    path: '/mover',
    component: () => import(/* webpackChunkName: "mover" */ './views/Mover')
  },
  {
    path: '/trash',
    component: () => import(/* webpackChunkName: "trash" */ './views/Trash')
  }
]

export default routes
