// cSpell: disable

export default {
  _code: 'ro',
  _name: 'Română',
  email: {
    label: 'Adresa email',
    placeholder: 'admin@email.ro'
  },
  password: {
    label: 'Parola',
    placeholder: 'secret',
    invalid: 'Parola invalida: min 6 caractere'
  },
  login: {
    button: 'Login',
    title: 'Acces securizat'
  },
  logout: {
    nav: 'Logout',
    button: 'Logout',
    confirm: 'Sunteti sigur ca doriti sa parasiti sectiunea securizata?'
  },
  contacts: {
    nav: 'Contacte',
    settings: 'Configurare contacte',
    name: 'Nume',
    company: 'Companie',
    isAgency: 'Agentie',
    phones: 'Telefon',
    emails: 'Email',
    sites: 'Web'
  },
  types: {
    nav: 'Tipuri proprietate',
    listNav: 'Nomenclator tipuri proprietati',
    name: 'Nume'
  },
  zones: {
    nav: 'Zone',
    listNav: 'Nomenclator zone',
    name: 'Nume'
  },
  estates: {
    nav: 'Proprietati',
    settings: 'Configurare proprietati',
    create: 'Noua proprietate',
    edit: 'Modificare proprietate',
    view: 'Vizualizare proprietate',
    select: 'Va rugam selectati tipul de proprietate',
    title: 'Titlu',
    zones: 'Zone',
    location: 'Geolocatie',
    priceSale: 'Pret vanzare',
    priceRent: 'Pret inchiriere',
    type: 'Tip',
    contacts: 'Proprietar',
    transaction: 'Tranzactie',
    summary: 'Note agent (nepublicate)',
    unpublishedInfo: 'Informatii nepublicate',
    description: 'Descriere',
    rentals: 'Inchirieri',
    sales: 'Vanzari',
    images: 'Imagini',
    plans: 'Planuri, media',
    status: 'Stare',
    archived: 'Arhiva',
    filter: 'Filtreaza',
    rental: 'Inchiriere',
    sale: 'Vanzare',
    visualisations: 'Vizualizari',
    address: 'Adresa',
    isPublished: 'Publicata',
    isPrivate: 'Privata',
    isArchived: 'Arhivata',
    confirmSaveText: 'Sunteti sigur ca doriti salvarea acestei proprietati?',
    required: 'Camp invalid: {0}',
    tags: 'Etichete',
    viewEmpty: {
      title: 'Proprietate negasita',
      text: 'Propritatea specificata nu a putut fi gasita. Aceasta eroare a fost inregistrata.',
      cta: 'Inapoi la lista'
    },
    deleteTitle: 'Stergere proprietate',
    deleteText:
      'Stergerea unei proprietati atrage dupa sine stergerea tuturor datelor cu privire la aceasta proprietate, inclusiv imaginile si fisierele atasate acesteia. Sunteti sigur ca doriti stergerea acestei proprietati?'
  },
  templates: {
    nav: 'Sabloane',
    listNav: 'Sabloane',
    context: 'Context',
    description: 'Descriere proprietati',
    body: 'Continut'
  },
  media: {
    nav: 'Media',
    filter: {
      all: 'Afiseaza...',
      img: 'Numai imagini',
      doc: 'Numai documente'
    },
    name: 'Nume',
    mime: 'Tip',
    url: 'URL',
    storage: 'Stocat',
    identifier: 'ID unic',
    size: 'Marime',
    load: 'Dimensiuni',
    alt: 'Descriere',
    upload: 'Upload {0} fisiere',
    estateSelectedPublic: 'Imagine publicata la',
    estateSelectedProtected: 'Imagine privata la',
    estateSelectedNone: 'Nu au fost gasite proprietati',
    packEmpty: 'Selectati una sau mai multe imagini...',
    packSend: 'Trimite {size}',
    trashCta: 'Sterge {num} fisier(e) (size)',
    tempText:
      'Fisierele temporare sunt fisiere ramase ca urmare a unei intreruperi sau erori in procesul incarcarii unei imagini pe server. Pot fi sterse daca sunteti sigur ca alt utilizator nu incarca imagini chiar in acest moment.',
    delCta: 'Sterge',
    trashSize: 'Spatiu ocupat: {1}',
    invalidText:
      'Fisierele invalide sunt intrari in baza de date care nu au un fisier stocat pe server, rezultand in erori la afisarea acestora. Pot fi duplicate ale fisierelor orfane, stergerea acestora putand influenta numarul fisierelor orfane.',
    storageText:
      'Fisierele stocate fara referinta sunt fisiere prezente pe disc dar fara nicio referinta in baza de date. Pot fi sterse in siguranta, neavand un impact direct asupra bazei de date.',
    orphanedText:
      'Fisierele orfane sunt prezente in baza de date dar nu sunt referentiate de nicio proprietate (publicata sau activa) a dvs. Puteti opta pentru a sterge toate aceste fisiere sau selectiv, folosind lista de mai jos.',
    orphanedCta: 'Sterge {0} selectate ({1} marime)',
    delConfirmTitle: 'Stergere definitiva',
    delConfirmText:
      'Aceasta operatiune este definitiva, nefiind nicio posibilitate de a recupera datele. Sunteti sigur ca doriti stergerea {0} fisiere ({1})?',
    allUsers:
      'Atentie: informatiile si actiunile din aceasta sectiune se refera la toti utilizatorii, avand impact asupra intregii baze de date si a sistemului de fisiere!'
  },
  special: {
    title: 'Creaza link special',
    images: 'Include imagini si planuri:',
    summary: 'Include note agent',
    address: 'Include adresa',
    contact: 'Include contact',
    press_generate: 'Apasati Genereaza',
    generate: 'Genereaza'
  },
  mover: {
    title: 'Mutare date',
    text: 'Atentie: aceasta operatie nu poate fi anulata si revenirea la starea initiala nu este posibila. Odata inceputa, este importanta sabilitatea conexiunii, astfel ca este recomandat a nu se realiza de pe dispozitive mobile conectate prin 3G/4G. O conexiune WiFi stabila este foarte recomandata.',
    from: 'Sursa',
    to: 'Destinatie',
    select: 'Va rugam selectati',
    action: 'Muta date',
    same: 'Selectati o destinatie diferita de sursa',
    estates: 'Proprietati',
    contacts: 'Contacte',
    images: 'Imagini',
    error: 'A aparut o eroare la mutarea datelor',
    success:
      'Datele au fost mutate cu succes ({estates} proprietati, {images} imagini, {contacts} contacte! Va rugam asteptati reincarcarea datelor'
  },
  pricing: {
    unit: {
      whole: 'total',
      mp: '/ mp'
    },
    interval: {
      day: '/ zi',
      week: '/ saptamana',
      month: '/ luna',
      year: '/ an'
    }
  },
  unauthorized: {
    title: 'Acces neautorizat',
    text: 'Se pare ca nu aveti acces la resursa / operatiunea ceruta. Aceasta eroare a fost inregistrata si va fi analizata.'
  },
  fieldTypes: {
    title: 'Tip camp',
    name: 'Nume camp',
    array: 'Lista generica',
    bool: 'Da/Nu',
    collection: 'Nomenclator',
    date: 'Data (ziua)',
    file: 'Lista fisiere',
    geo: 'Geolocatie',
    list: 'Lista predefinita',
    media: 'Lista imagini',
    password: 'Parola',
    number: 'Numar',
    euro: {
      rent: 'Pret inchiriere',
      sale: 'Pret vanzare'
    },
    text: 'Text mare',
    string: 'Text simplu',
    unix: 'Moment',
    warning: 'Indicativ rosu',
    tag: 'Etichete'
  },
  roles: {
    ADMIN: 'Administrator',
    AGENT: 'Agent'
  },
  profile: {
    nav: 'Profil',
    name: 'Nume si prenume',
    role: 'Nivel',
    email: 'Adresa email',
    phone: 'Numar telefon',
    changePassword: 'Schimba parola curenta',
    passwordOld: 'Vechea parola',
    password: 'Noua parola',
    passwordAgain: 'Noua parola (inca odata)',
    hide: 'Lista site web Companie / Agenti'
  },
  users: {
    nav: 'Agenti',
    heading: {
      name: 'Nume',
      email: 'Email',
      phone: 'Telefon',
      role: 'Rol',
      password: 'Parola'
    },
    roles: {
      admin: 'Administrator',
      agent: 'Agent'
    },
    hides: {
      yes: 'Ascunde pe site',
      no: 'Listeaza in pagina Agenti',
      short: 'Ascuns'
    },
    itsYou: 'Curent',
    destroyTitle: 'Sterge {name}',
    destroyText:
      'Stergerea unui utilizator este definitiva. Odata cu stergerea utilizatorului, se vor sterge toate contactele din agenda sa. Daca sunt proprietati asignate utilizatorului, acesta nu va putea fi sters. Sunteti sigur ca doriti stergerea utilizatorului {name} cu adresa email {email}?'
  },
  home: {
    nav: 'Acasa'
  },
  settings: {
    nav: 'Configurare',
    structure: 'Structura date',
    lists: 'Nomenclatoare',
    title: 'Configurare {name}'
  },
  grid: {
    total: 'Total',
    filtered: 'Filtrate',
    pages: 'Pagini',
    configure: 'Configureaza tabel'
  },
  actions: {
    add: 'Adauga',
    addName: 'Adauga {name}',
    edit: 'Modifica',
    editName: 'Modifica {name}',
    cancel: 'Abandon',
    close: 'Inchide',
    save: 'Salveaza',
    no: 'Nu',
    yes: 'Da',
    yesSure: 'Da, sunt sigur',
    ok: 'OK',
    clearFilter: 'Reset filtru',
    change: 'Schimba',
    destroy: 'Sterge',
    eliminate: 'Elimina',
    reset: 'Reset'
  },
  bool: {
    yes: 'Da',
    no: 'Nu'
  },
  general: {
    search: 'Cauta...',
    createdAt: 'Creat',
    updatedAt: 'Modificat',
    confirm: 'Sunteti sigur ca doriti efectuarea acestei operatiuni?',
    includeAll: 'Include toate',
    all: 'Toate',
    includeOnly: 'Include numai',
    filter: 'Filtrare',
    from: 'De la',
    to: 'Pana la',
    prevPage: 'Precedenta',
    nextPage: 'Urmatoare',
    reset: 'Reseteaza',
    pleaseSelect: 'Va rugam selectati...',
    copied: 'Copiat',
    both: 'Amandoua',
    unknown: 'Necunoscut',
    onePerLine: 'Introduceti cate un element pe linie.',
    verifyField: 'Verificati campul {0} !',
    formHasErrors: 'Formularul contine erori!',
    addItem: 'Adauga element',
    multipleSelect: 'Permite selectare multipla'
  },
  impersonate: {
    title: 'Login ca utilizator {name}',
    text: 'Pentru a putea realiza login ca utilizator {name} ({email}) va rugam introduceti parola dvs. de admin, cea folosita pentru a va loga in sistem.',
    password: 'Parola dvs. de administrator',
    button: 'Login'
  },
  orderBy: {
    asc: {
      createdAt: 'Data adaugarii, ascendent',
      updatedAt: 'Data modificarii, ascendent',
      name: 'Nume, ascendent',
      load: 'Marime, ascendent',
      size: 'Dimensiuni, ascendent'
    },
    desc: {
      createdAt: 'Data adaugarii, descendent',
      updatedAt: 'Data modificarii, descendent',
      name: 'Nume, descendent',
      load: 'Marime, descendent',
      size: 'Dimensiuni, descendent'
    }
  },
  upload: {
    placeholder:
      'Drag & Drop fisiere imagine (jpg, png, gif) in aceasta arie sau click butonul de mai jos',
    button: 'Alege fisiere...'
  },
  aria: {
    pagination: 'Paginare',
    navFirst: 'Prima pagina',
    navPrev: 'Pagina precedenta',
    navNext: 'Pagina urmatoare',
    navLast: 'Ultima pagina ({0})'
  },
  errors: {
    invalid_credentials: 'Email sau parola gresite',
    save: 'Eroare la salvarea datelor',
    create: 'Eroare la crearea datelor',
    update: 'Eroare la salvarea datelor',
    destroy: 'Eroare la stergerea datelor',
    estateAssigned:
      'Eroare la stergerea datelor: exista cel putin o proprietate care utilizeaza aceste date.',
    mediaUpload: 'Eroare la incarcare imagini',
    profileUpdate: 'Eroare la salvarea profilului',
    roDelete: 'Aceasta resursa nu poate fi stearsa.',
    geo: 'Coordonatele GPS nu pot fi citite.',
    impersonating: 'Logarea cu utilizator diferit a esuat.',
    email: 'Eroare la trimiterea mesajului email.'
  },
  messages: {
    loading: 'Incarcare...',
    created: 'Datele au fost create cu success',
    updated: 'Datele au fost salvate cu succes',
    destroyed: 'Datele au fost sterse cu succes',
    leavePage:
      'Parasirea paginii va duce la pierderea datelor editate. Sunteti sigur?',
    geo: 'Click pentru citirea coordonatelor GPS...'
  },
  form: {
    name: 'Nume',
    untitled: 'Nou'
  },
  column: {
    show: 'Arata in tabel',
    fill: 'Completeaza in formular',
    tag: 'Camp eticheta',
    positionInvert: 'Valoare la stanga'
  },
  tabs: {
    general: 'General',
    tags: 'Etichete'
  },
  tagsEmpty: {
    title: 'Fara etichete',
    text: 'Etichetele sunt un mod usor si rapid pentru a introduce caracteristicile unei proprietati. Folositi butoanele pentru a adauga o noua eticheta. Dupa adaugare, aveti optiunea de a modifica ordinea etichetelor introduse si de a edita valorile acestora.'
  },
  validation: {
    required: 'Camp necesar',
    email: 'Adresa email invalida',
    min: 'Minim {0} caractere',
    max: 'Maximum {0} caractere',
    invalid: 'Camp invalid'
  },
  export: {
    title: 'Export',
    button: 'Export'
  },
  notYetImplemented: 'Aceasta functionalitate este in lucru',
  noRights: 'Nu aveti dreptul sa efectuati aceassta operatiune'
}
