<template>
  <nav role="navigation" class="
      h-12
      shadow-sm
      w-full
      bg-white
      flex
      justify-between
      items-center
      fixed
      inset-x-0
      top-0
      z-30
    ">
    <div id="left-nav" class="mx-1">
      <button class="
          w-10
          h-10
          flex
          items-center
          justify-center
          rounded
          mx-1
          md:hidden
        " @click="isExpanded = !isExpanded">
        <icon :face="isExpanded ? 'mdiWindowClose' : 'mdiMenu'" />
      </button>
      <div class="
          absolute
          top-12
          left-0
          bg-white
          z-10
          w-12
          items-center
          shadow
          px-2
          py-2
          md:py-0
          flex-col
          md:static md:w-auto md:flex-row md:shadow-none md:top-0
        " :class="{
          'hidden md:flex': !isExpanded,
          flex: isExpanded
        }">
        <router-link to="/" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-purple-700
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav-exact
          ">
          <icon face="mdiFullscreen" />
        </router-link>
        <router-link to="/estates" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-green-700
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav
          ">
          <icon face="mdiHome" />
        </router-link>
        <router-link to="/archives" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-yellow-500
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav
          ">
          <icon face="mdiArchive" />
        </router-link>
        <router-link to="/contacts" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-pink-700
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav
          ">
          <icon face="mdiContacts" />
        </router-link>
        <router-link to="/media" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-yellow-700
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav
          ">
          <icon face="mdiImage" />
        </router-link>
        <router-link v-if="isAdmin" to="/mover" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-blue-700
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav
          ">
          <icon face="mdiContentCopy" />
        </router-link>
        <router-link v-if="isAdmin" to="/trash" class="
            w-10
            h-10
            flex
            items-center
            justify-center
            rounded
            text-red-700
            bg-gray-50
            mx-1
            mt-1
            md:mt-0
            btn-nav
          ">
          <icon face="mdiDeleteForeverOutline" />
        </router-link>
      </div>
    </div>
    <div id="center-nav" class="mx-2">
      <slot />
    </div>
    <div id="right-nav" class="flex mx-2 items-center">
      <dropdown-menu right-align class="h-12 w-12 flex items-center justify-center" :items="menus.settings">
        <template #trigger>
          <icon face="mdiCog" />
        </template>
      </dropdown-menu>
      <dropdown-menu right-align class="h-12 w-12 flex items-center justify-center">
        <template #trigger>
          <img :src="avatarSrc" class="object-fill rounded-full border w-8 h-8" />
        </template>
        <template #menu>
          <router-link to="/profile" class="px-6 py-3 hover:bg-active hover:text-inactive text-sm">{{ $t('profile.nav')
            }}</router-link>
          <a href="#" @click.prevent="onLogout" class="px-6 py-3 hover:bg-active hover:text-inactive text-sm">{{
            $t('logout.nav') }}</a>
        </template>
      </dropdown-menu>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { storage } from '@/services'

export default {
  name: 'Navigation',
  props: {
    user: Object
  },
  computed: {
    ...mapState({ isAdmin: (state) => state.user?.role === 'ADMIN' }),
    avatarSrc() {
      return `${process.env.VUE_APP_SERVER_URL}/media/avatar/${this.user._id}.png?_=${this.nocache}`
    },
    menus() {
      const _menus = {
        settings: [
          {
            id: 'settings.grids',
            label: 'settings.nav'
          },
          {
            id: 'settings.estates',
            to: '/settings/estates',
            label: 'estates.nav'
          },
          {
            id: 'settings.contacts',
            to: '/settings/contacts',
            label: 'contacts.nav'
          },
          {
            id: 'settings.lists',
            label: 'settings.lists'
          },
          {
            id: 'lists.zones',
            to: '/lists/zones',
            label: 'zones.nav'
          },
          {
            id: 'lists.types',
            to: '/lists/types',
            label: 'types.nav'
          },
          {
            id: 'lists.templates',
            to: '/lists/templates',
            label: 'templates.nav'
          },
          {
            id: 'lists.users',
            to: '/lists/users',
            label: 'users.nav'
          }
        ]
      }
      return _menus
    }
  },
  data() {
    const nocache = Math.floor(100000 * Math.random())

    return {
      nocache,
      isExpanded: false
    }
  },
  mounted() {
    this.$bus.$on('avatar-changed', this.refreshAvatar)
  },
  beforeDestroy() {
    this.$bus.$off('avatar-changed', this.refreshAvatar)
  },
  methods: {
    onLogout() {
      storage.token = null
      this.$store.commit('logout')
      this.$router.push('/login')
    },
    refreshAvatar() {
      this.nocache = Math.floor(1000000 * Math.random())
      return true
    }
  }
}
</script>
