<template>
  <div class="flex items-center justify-start">
    <button :class="btnClasses('first', isDisabledPrev)" type="button" :disabled="isDisabledPrev" v-if="showAbs"
      @click="onSelectPage(0)">
      <icon face="mdiChevronDoubleLeft"></icon>
    </button>
    <button :class="btnClasses('prev', isDisabledPrev)" type="button" :disabled="isDisabledPrev" v-if="showOne"
      @click="onSelectPage(page - 1)">
      <icon face="mdiChevronLeft"></icon>
    </button>
    <select class="
        appearance-none
        block
        border-t border-b border-blue-500
        h-10
        px-4
        text-center
        outline-none
      " v-if="showAbs" :value="page" @input="(e) => onSelectPage(e.target.value)">
      <option v-for="p in allPages" :value="p.value" :key="p.key">
        {{ p.label }}
      </option>
    </select>
    <button :class="btnClasses('next', isDisabledNext)" type="button" :disabled="isDisabledNext" v-if="showOne"
      @click="onSelectPage(page + 1)">
      <icon face="mdiChevronRight"></icon>
    </button>
    <button :class="btnClasses('last', isDisabledNext)" type="button" :disabled="isDisabledNext" v-if="showAbs"
      @click="onSelectPage(lastPage - 1)">
      <icon face="mdiChevronDoubleRight"></icon>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    page: { type: Number, default: 0 },
    last: { type: Number, default: 0 },
    count: { type: Number, default: 0 },
    size: { type: String, default: 'xs' },
    show: { type: String, default: 'abs one sel' }
  },
  computed: {
    showButtons() {
      return this.show.toLowerCase().split(' ')
    },
    showAbs() {
      return this.showButtons.includes('abs') && this.count > 3
    },
    showOne() {
      return this.showButtons.includes('one')
    },
    showSel() {
      return this.showButtons.includes('sel')
    },
    lastPage() {
      return this.last
    },
    isDisabledPrev() {
      return this.page === 0
    },
    isDisabledNext() {
      return this.page === this.lastPage - 1
    },
    allPages() {
      const all = []
      for (let i = 0; i < this.lastPage; i++) {
        all.push({ key: i, value: i, label: `${i + 1}` })
      }
      return all
    }
  },
  methods: {
    btnClasses(type, isDisabled) {
      const common = [
        'w-10',
        'h-10',
        'flex',
        'items-center',
        'justify-center',
        'text-white',
        'bg-blue-700'
      ]
      const btnClasses = {
        first: 'rounded-l',
        last: 'rounded-r',
        prev: this.showAbs ? '' : 'rounded-l',
        next: this.showAbs ? '' : 'rounded-r'
      }
      if (btnClasses[type]) {
        common.push(btnClasses[type])
      }
      if (isDisabled) {
        common.push('opacity-30 pointer-events-none')
      }
      return common.join(' ')
    },
    onSelectPage(p) {
      let _p = parseInt(p)
      this.$emit('change', _p >= 0 ? _p : 0)
    }
  }
}
</script>
