<template>
  <div class="relative">
    <div v-if="isUploading" class="
        absolute
        inset-0
        bg-white bg-opacity-75
        flex
        items-center
        justify-center
        z-50
        text-blue-600
      ">
      <icon face="mdiRefresh" size="4rem" spin />
    </div>
    <div :class="{
      'overflow-y-auto': !currentImage && !hasOverride,
      'overflow-hidden': currentImage && !hasOverride
    }" :style="computedStyle">
      <div class="flex flex-row flex-wrap">
        <media-card class="w-1/2 sm:w-1/3 lg:w-1/6" v-for="img in paginatedThumbs" :key="img._key" :img="img"
          :selected="selected" @select="onSelect(img)" :dimensions="imageDimensions[img._key]"
          @load="onImageLoad(img)" />
      </div>
      <div class="py-8 flex items-center justify-center">
        <pagination :page="page" :last="lastPage" :count="thumbs.length" @change="onChangePage" />
      </div>
    </div>
    <div class="absolute inset-0 flex flex-col md:flex-row bg-white" v-if="currentImage">
      <button @click="currentImage = null" :disabled="isBusy" type="button" class="
          absolute
          right-2
          top-2
          w-10
          h-10
          text-2xl
          flex
          items-center
          justify-center
          cursor-pointer
          bg-transparent
          text-gray-600
          hover:text-black
          bg-white
          rounded-sm
          shadow
          z-50
        ">
        <icon face="mdiWindowClose" />
      </button>
      <div class="w-full flex-1 p-1 bg-gray-100" v-if="currentImage._isImage">
        <div class="no-pattern-bg h-full w-full relative">
          <div class="
              absolute
              bg-no-repeat bg-center bg-contain
              inset-0
              md:inset-2
            " :style="{
              backgroundImage: `url(${currentImage._thumb})`
            }"></div>
        </div>
      </div>
      <div class="w-full p-4 bg-gray-100 flex-shrink-0" :class="{
        'md:w-1/3': currentImage._isImage
      }">
        <div class="h-full relative">
          <div class="overflow-y-auto">
            <div v-for="item in currentImageDetails" :key="item.id">
              <label class="font-bold text-sm">{{ $t(item.label) }}</label>
              <p class="
                  text-xs
                  whitespace-nowrap
                  max-w-full
                  overflow-hidden overflow-ellipsis
                " :title="item.value">
                {{ item.value }}
              </p>
            </div>
            <div class="mb-2">
              <label class="font-bold text-sm">{{ $t('media.url') }}</label>
              <a target="_blank" :download="currentImage.original" class="
                  text-blue-500
                  whitespace-nowrap
                  max-w-full
                  overflow-hidden overflow-ellipsis
                  block
                  hover:underline
                  text-xs
                " :href="currentImage._thumb" :title="currentImage._thumb">{{ currentImage._thumb }}</a>
            </div>
          </div>
          <hr />
          <div class="flex-shrink-0 flex mt-1">
            <confirm icons-only @click="onImageDelete" class="mt-4 md:mt-0" :disabled="isBusy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { getMediaURL, getNiceSize } from '@/services'
import MediaCard from '@/components/media/MediaCard'

import fileIcon from '@/assets/file-icon.png'

export default {
  name: 'MediaGrid',
  components: { MediaCard },
  props: {
    search: { type: String, default: '' },
    pick: { type: String, default: 'details' },
    selected: { type: Array, default: () => [] },
    filter: { type: String, default: 'all' },
    override: { type: Array },
    hasOverride: { type: Boolean, default: false }
  },
  watch: {
    search(to, from) {
      if (from === to) {
        return
      }
      this.page = 0
    }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) => state.images.loading || state.images.updating,
      isAdmin: (state) => state.user?.role === 'ADMIN',
      images: (state) => state.images
    }),
    media() {
      return this.hasOverride ? this.override : this.images?.items
    },
    fileIcon() {
      return fileIcon
    },
    filteredByType() {
      if (this.filter === 'all') {
        return this.media
      }
      const _filter =
        this.filter === 'doc'
          ? (img) => !img.mime.startsWith('image/')
          : (img) => img.mime.startsWith('image/')
      return this.media?.filter(_filter)
    },
    filteredImages() {
      const ordered = this.filteredByType
        .slice(0)
        .sort((a, b) => b.createdAt - a.createdAt)

      const _search = this.search.trim().toLowerCase()
      if (!_search) {
        return ordered
      }
      return ordered.filter((img) => {
        return img.name.toLowerCase().includes(_search)
      })
    },
    thumbs() {
      return this.filteredImages.slice(0).map((img) => ({
        ...img,
        _thumb: getMediaURL(img),
        _niceSize: getNiceSize(img, 0),
        _key: `image-${img._id}`,
        _isImage: img.mime.startsWith('image/'),
        _mime: this.getMime(img)
      }))
    },
    paginatedThumbs() {
      const start = this.page * this.chunk
      const end = start + this.chunk
      return this.thumbs.slice(start, end)
    },
    lastPage() {
      const count = this.thumbs.length
      let lp = parseInt(count / this.chunk)
      return lp + (count % this.chunk > 0 ? 1 : 0)
    },
    currentImageDetails() {
      if (!this.currentImage) {
        return []
      }
      return [
        { id: 'name', label: 'media.name', value: this.currentImage.original },
        { id: 'mime', label: 'media.mime', value: this.currentImage.mime },
        // {
        //   id: 'storage',
        //   label: 'media.storage',
        //   value: this.currentImage.storageFile
        // },
        {
          id: 'load',
          label: 'media.load',
          value: this.imageDimensions[this.currentImage._key]
            ? `${this.imageDimensions[this.currentImage._key].width} × ${this.imageDimensions[this.currentImage._key].height
            } px`
            : '-'
        },
        {
          id: 'size',
          label: 'media.size',
          value: `${this.currentImage._niceSize.value} ${this.currentImage._niceSize.unit
            } ${this.currentImage._niceSize.unit !== 'b'
              ? ` (${this.currentImage.size}b)`
              : ''
            }`
        },
        {
          id: 'created',
          label: 'general.createdAt',
          value: moment.utc(this.currentImage.createdAt).local().format('LLLL')
        }
      ]
    }
  },
  data() {
    return {
      imageDimensions: {},
      currentImage: null,
      computedStyle: {},
      isUploading: false,
      page: 0,
      chunk: 24
    }
  },
  mounted() {
    this.$bus.$on('images-upload', this.onUploadImages)
    this.$bus.$on('begin-images-upload', this.beginUpload)
    this.$bus.$on('paginate-offset', this.onPaginateOffset)
    if (!this.hasOverride) {
      const rect = this.$el.getBoundingClientRect()
      this.computedStyle = {
        height: `calc(100vh - ${rect.top}px)`
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off('images-upload', this.onUploadImages)
    this.$bus.$off('begin-images-upload', this.beginUpload)
    this.$bus.$off('paginate-offset', this.onPaginateOffset)
  },
  methods: {
    ...mapActions(['images/create', 'images/destroy']),
    onChangePage(p) {
      this.page = p
    },
    getMime({ mime, extension }) {
      const _mimes = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp'
      }

      return _mimes[mime] || extension || '?'
    },
    beginUpload() {
      this.isUploading = true
    },
    async onUploadImages(data) {
      try {
        this.isUploading = true
        const promises = data.map(
          (upload) => async () => this['images/create'](upload)
        )
        await Promise.all(promises.map((p) => p()))
      } catch (err) {
        console.error('Error while uploading', err)
      } finally {
        this.isUploading = false
      }
    },
    onImageLoad(img) {
      const el = document.getElementById(img._key)
      if (el && el.naturalHeight && el.naturalWidth) {
        this.$set(this.imageDimensions, img._key, {
          width: el.naturalWidth,
          height: el.naturalHeight
        })
      }
    },
    isSelected({ _id }) {
      return this.selected.includes(_id)
    },
    onSelect(img) {
      let idx, nv
      switch (this.pick) {
        case 'model':
          nv = this.selected.slice(0)
          idx = nv.findIndex((item) => item === img._id)
          if (idx >= 0) {
            nv.splice(idx, 1)
          } else {
            nv.push(img._id)
          }
          return this.$emit(
            'select',
            nv,
            this.media.filter((img) => nv.includes(img._id))
          )
        default:
          this.currentImage = img
      }
    },
    onPaginateOffset(offset) {
      if (offset < 0 && this.page === 0) {
        return
      }
      if (offset > 0 && this.page === this.lastPage - 1) {
        return
      }
      this.page = this.page + offset
    },
    async onImageDelete(img) {
      const _img = img || this.currentImage
      if (!_img) {
        return
      }
      await this['images/destroy'](_img)
      this.currentImage = null
    }
  }
}
</script>
